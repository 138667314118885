window.onload = function () {

  /* show / hide main nav
  -------------------------------------------------- */
  var btnnav = document.querySelector('.js-togglemenu');
  var mobilenav = document.querySelector('.js-navwrapper');

  btnnav.onclick = function () {
    btnnav.classList.toggle('active');
    mobilenav.classList.toggle('active');
  }
/* change logo on width
  -------------------------------------------------- */
  var logoinhalt = document.getElementsByClassName('logo--img')[0];
  if (window.innerWidth <= 990){
    logoinhalt.src = "resources/images/Logo/scroll-logo.svg";
  }
  else {
    logoinhalt.src = "resources/images/Logo/logo.svg";
  }
/* glides Slider 
  -------------------------------------------------- */
  
  const glides = document.querySelectorAll(".glide");

  Object.values(glides).map(glide => {
    const slider = new Glide(glide, {
       type: 'carousel',
        preView: 1,
        autoplay: 5000
    });
    slider.mount();
  });
 

};
/* change logo on resize
  -------------------------------------------------- */
  var logoinhalt = document.getElementsByClassName('logo--img')[0];
  
  window.addEventListener('resize', function () {
    
  if (window.innerWidth <= 990){
    logoinhalt.src = "resources/images/Logo/scroll-logo.svg";
  }
  else {
    logoinhalt.src = "resources/images/Logo/logo.svg";
  }
  });

/* change header on scroll
  -------------------------------------------------- */
var scrollPosition = window.scrollY;
var logoContainer = document.getElementsByClassName('js-header-height')[0];
var logoinhalt = document.getElementsByClassName('logo--img')[0];
window.addEventListener('scroll', function () {

  scrollPosition = window.scrollY;

  if (scrollPosition >= 30) {
    logoContainer.classList.add('height--small');
    logoinhalt.src = "resources/images/Logo/scroll-logo.svg";
  } else {

    if (window.innerWidth <= 990){
      logoContainer.classList.remove('height--small');
      logoinhalt.src = "resources/images/Logo/scroll-logo.svg";
    }
    else {
      logoContainer.classList.remove('height--small');
      logoinhalt.src = "resources/images/Logo/logo.svg";
    }
    // logoContainer.classList.remove('height--small');
    // logoinhalt.src = "resources/images/Logo/logo.svg";
  }

  
});

/* toggle contact 
  -------------------------------------------------- */
function classToggle() {
 
  this.classList.toggle('active');
};

var togglecontact = document.querySelector('.js_toggel_contact');
var btncontactopen = document.querySelector('.js_open_contact');
var btncontactclose = document.querySelector('.js_close_contact');
var contactflip = document.querySelector('.contact_flap');


  contactflip.addEventListener('click',classToggle);
  

btncontactopen.addEventListener('click',classToggle);
btncontactclose.addEventListener('click',classToggle);




